
import { Options } from "vue-class-component";
import ComponentMixins from "../ComponentMixins";
import FormDesignRender from "../../../setting/form-setting/FormDesignRender/FormDesignRender.vue";
import { ValueType } from "../ComponentsConfigObject";

@Options({
    name: "flowable-common-form-components-table-list",
    components: { FormDesignRender },
    props: {
        placeholder: {
            type: String,
            default: "添加数据",
        },
        columns: {
            type: Array,
            default: (): any => {
                return [];
            },
        },
        showBorder: {
            type: Boolean,
            default: true,
        },
        maxSize: {
            type: Number,
            default: 0,
        },
        rowLayout: {
            type: Boolean,
            default: true,
        },
    },
})
export default class TableList extends ComponentMixins {
    private select: any = null;
    private drag = false;
    private columns: any;
    private maxSize: number;
    private rowLayout: boolean;

    public created() {
        if (!Array.isArray(this._value)) {
            this._value = [];
        }
    }
    get rules() {
        const rules: any = {};
        this.columns.forEach((col: any) => {
            if (col.props.required) {
                rules[col.id] = [
                    {
                        type: col.valueType === "Array" ? "array" : undefined,
                        required: true,
                        message: `请填写${col.title}`,
                        trigger: "blur",
                    },
                ];
            }
        });
        return rules;
    }
    get _columns() {
        return this.columns;
    }
    set _columns(val) {
        this.columns = val;
    }
    get selectFormItem() {
        return this.$store.getters["flowable/selectFormItem"];
    }
    set selectFormItem(val: any) {
        this.$store.dispatch("flowable/setSelectFormItem", val).then();
    }

    public getMinWidth(col: any) {
        switch (col.name) {
            case "DateTime":
                return "250px";
            case "DateTimeRange":
                return "280px";
            case "MultipleSelect":
                return "200px";
            default:
                return "150px";
        }
    }
    public showError(col: any, val: any) {
        if (col.props.required) {
            switch (col.valueType) {
                case ValueType.dept:
                case ValueType.user:
                case ValueType.dateRange:
                case ValueType.array:
                    return !(Array.isArray(val) && val.length > 0);
                default:
                    return !this.$tools.isEmpty(val);
            }
        }
        return false;
    }
    public copyData(i: any, row: any) {
        this._value.push(this.$tools.deepCopy(row));
    }
    public delRow(i: any, row: any) {
        this._value.splice(i, 1);
    }
    public addRow() {
        if (this.maxSize > 0 && this._value.length >= this.maxSize) {
            this.toast.warning(`最多只能添加${this.maxSize}行`);
        } else {
            let row: any = {};
            //this.columns.forEach((col) => this.$set(row, col.id, undefined));
            this.columns.forEach((col: any): any => (row[col.id] = undefined));
            this._value.push(row);
            //this.$set(this, "_value", this._value);
        }
    }
    public delItem(id: any) {
        this._columns.splice(id, 1);
    }
    public selectItem(cp: any) {
        this.selectFormItem = cp;
    }
    public getSelectedClass(cp: any) {
        return this.selectFormItem && this.selectFormItem.id === cp.id ? "border-left: 4px solid #f56c6c" : "";
    }
    public validate(call: any) {
        if (this.rowLayout) {
            let result = true;
            for (let i = 0; i < this.columns.length; i++) {
                if (this.columns[i].props.required) {
                    for (let j = 0; j < this._value.length; j++) {
                        result = !this.showError(this.columns[i], this._value[j][this.columns[i].id]);
                        if (!result) {
                            call(false);
                            return;
                        }
                    }
                }
            }
            call(result);
        } else {
            let success = 0;
            this._value.forEach((v: any, i: any) => {
                let formRef = this.$refs[`table-form-${i}`];
                if (formRef && Array.isArray(formRef) && formRef.length > 0) {
                    formRef[0].validate((valid: any) => {
                        if (valid) {
                            success++;
                        }
                    });
                }
            });
            if (success === this._value.length) {
                call(true);
            } else {
                call(false);
            }
        }
    }
}
